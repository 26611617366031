import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import './styles/loading.css';

const Loading = () => (
  <div className="loading-page">
    <h1>Loading</h1>
    <Spinner animation="border" variant="secondary" size="xxl" />
  </div>
); export default Loading;
