import React from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import FBLoginBtn from './FBLoginBtn';
import FacebookPages from './FacebookPages';
import FBLogoutBtn from './FBLogoutBtn';

const FacebookAccount = ({ facebookAccData, checkFacebookBind }) => {
  if (facebookAccData) {
    if (facebookAccData.facebook_bind === true && facebookAccData.expired === false) {
      return (
        <Card style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title>{facebookAccData.facebook_name}</Card.Title>
            <FBLogoutBtn FbId={facebookAccData.facebook_id} checkFBData={checkFacebookBind} />
            <Card.Text>Your facebook Pages:</Card.Text>
            { (facebookAccData.facebook_data.facebook_pages ?
                facebookAccData.facebook_data.facebook_pages.map(
                (facebookPage) => (
                  <FacebookPages
                    key={facebookPage.page_id}
                    FBPage={facebookPage}
                    checkFacebookBind={checkFacebookBind}
                  />
                ),
              )
            :
            <p>It seems you don't have any facebook pages yet</p>)
            }
          </Card.Body>
        </Card>
      );
    }
    return (
      <Card style={{ width: '18rem' }}>
        <Card.Body>
          <Card.Title>Login to view your facebook account</Card.Title>
          <Card.Text>
            {facebookAccData.facebook_name}
          </Card.Text>
          <FBLoginBtn handleFBSubscription={checkFacebookBind} refreshUser />
        </Card.Body>
      </Card>
    );
  }
  return (
    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>No Connected Account</Card.Title>
        <Card.Text>
          Login to facebook to bind account
        </Card.Text>
        <FBLoginBtn handleFBSubscription={checkFacebookBind} />
      </Card.Body>
    </Card>
  );
};
FacebookAccount.propTypes = {
  facebookAccData: PropTypes.shape({
    facebook_bind: PropTypes.bool.isRequired,
    facebook_data: PropTypes.shape({
      facebook_pages: PropTypes.arrayOf(PropTypes.shape({
        page_id: PropTypes.number.isRequired,
        page_name: PropTypes.string.isRequired,
        instagram_id: PropTypes.number.isRequired,
        subscription: PropTypes.bool.isRequired,
        expired: PropTypes.bool.isRequired,
      })),
    }).isRequired,
    facebook_id: PropTypes.number.isRequired,
    facebook_name: PropTypes.string.isRequired,
    expired: PropTypes.bool.isRequired,
  }),
  checkFacebookBind: PropTypes.func,
};
FacebookAccount.defaultProps = {
  facebookAccData: null,
  checkFacebookBind: null,
}; export default FacebookAccount;
