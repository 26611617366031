import React from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import {useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loading from './Loading';

const Logout = ({handleLogout}) => {
    const navigate = useNavigate();
    // eslint-disable-next-line no-unused-vars
    // const handleLogout = async () => {
    //     const postUrl = '${process.env.REACT_APP_API_URL}/api/auth/logout';
    //     const header = {
    //     'X-CSRF-TOKEN': Cookies.get('csrf_access_token'),
    //     };
    //     await axios.post(postUrl, {}, { headers: header, withCredentials: true })
    //         .then((res) => {
    //             if (res.status === 200) {
    //                 console.log(res.data);
    //                 history.push('/login');
    //             };
    //         });
    // };
  return(<p onClick={(e)=>handleLogout()}>Logout</p>)
};
Logout.propTypes = {
    handleLogout: PropTypes.func.isRequired,
}
export default Logout;