import React from 'react';
import { useNavigate } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
// import Button from 'react-bootstrap/Button';

function Dashboard() {
  const navigate = useNavigate();
  const handleNavClick = (selectedLink) => {
    navigate(selectedLink);
  };
  return (
    <div className="dashboard-page">
      {/* <Nav
        activeKey="/dashboard"
        onSelect={handleNavClick}
      >
        <Nav.Item>
          <Nav.Link href="/dashboard">Dashboard</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="/profile">
            Profile
          </Nav.Link>
        </Nav.Item>
      </Nav> */}
    </div>
  );
} export default Dashboard;
