import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import FacebookLogin, { FacebookLoginClient } from '@greatsumini/react-facebook-login';
import Cookies from 'js-cookie';

const FBLoginBtn = ({ handleFBSubscription, refreshUser }) => {
  // const getFBProfile = () => FacebookLoginClient.getProfile()
  //   .then((res) => res.name)
  //   .catch((err) => {
  //     console.log(err);
  //     return null;
  //   });
  const handleFBLogin = (responseFB) => {
    FacebookLoginClient.getProfile(
      (fbRes) => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/auth/facebook/login`;
        const header = {
          'X-CSRF-TOKEN': Cookies.get('csrf_access_token'),
        };
        const FbData = { ...responseFB, name: fbRes.name };
        axios.post(apiUrl, FbData, { headers: header, withCredentials: true })
          .then(() => handleFBSubscription())
          .catch((err) => console.log(err));
      },
    );
  };

  const updateFBLogin = (responseFB) => {
    FacebookLoginClient.getProfile((fbRes) => {
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/auth/facebook/login`;
      const header = {
        'X-CSRF-TOKEN': Cookies.get('csrf_access_token'),
      };
      const FbData = { ...responseFB, name: fbRes.name };
      axios.put(apiUrl, FbData, { headers: header, withCredentials: true })
        .then(() => handleFBSubscription())
        .catch((err) => console.log(err));
    });
  };

  return (
    <div className="FBLoginBtn">
      <FacebookLogin
        appId="790536071666674"
        autoLoad={false}
        fields="name, email, picture"
        scope="instagram_basic,pages_manage_metadata,instagram_manage_comments,pages_read_engagement,pages_show_list,ads_management,business_management"
        initParams={{ version: 'v8.0' }}
        onSuccess={(response) => (refreshUser
          ? updateFBLogin(response) : handleFBLogin(response))}
        onFail={(error)=>alert(`facebook authentication failed: ${error}`)}
        style={{
          // backgroundColor: '#4267b2',
          backgroundColor:'#1877F2',
          color: '#fff',
          fontSize: '16px',
          padding: '12px 24px',
          border: 'none',
          borderRadius: '4px',
          width: '100%',
          height: 'auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          flexFlow:'row',
          gap:'5%'
        }}
        >
          <img style={{ width: '50px', height: 'auto', float: 'left' }} src="/f-logo_RGB-White_58.png" alt="fb-logo" />
          <p style={{ alignSelf: 'center', margin: '0' }}>Login with Facebook</p>
        </div>
      </FacebookLogin>
    </div>
  );
};
FBLoginBtn.defaultProps = {
  refreshUser: false,
};
FBLoginBtn.propTypes = {
  handleFBSubscription: PropTypes.func.isRequired,
  refreshUser: PropTypes.bool,
};
export default FBLoginBtn;
