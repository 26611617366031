/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import Nav from './Nav';
import './styles/navroute.css';

const NavRoute = ({ component: Component, handleLogout: setLogoutStatus, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <div className="page__wrapper">
        <Nav className="page__wrapper-nav" setLogoutStatus={setLogoutStatus}/>
        <Component {...props} className="page__wrapper-component" />
      </div>
    )}
  />
);
NavRoute.propTypes = {
  component: PropTypes.func.isRequired,
};
export default NavRoute;
