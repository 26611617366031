
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { BrowserRouter as Router, Route ,Routes} from 'react-router-dom';
import Loading from './pages/Loading';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Profile from './pages/Profile';
import Home from './pages/Home';
import PrivateRoute from './components/PrivateRoute';
import Signup from './pages/Signup';
import Loader from './components/Loader';
import RequireAuth from './components/RequireAuth';
import Nav from './components/Nav';
import './App.css';

function App() {
  const [csrfAccessToken, setCsrfAccessToken] = useState(Cookies.get('csrf_access_token'));
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleCookieChange = (value) => {
    setCsrfAccessToken(value);
  };

  const handleLogout = () => {
    setIsLoggedIn(false)
  };

  const handleCheckLogin = async () => {
    const echoUrl = `${process.env.REACT_APP_API_URL}/api/auth/echo`;
    const header = {
      'X-CSRF-TOKEN': Cookies.get('csrf_access_token'),
    };
    setIsLoading(true);
    await axios.get(echoUrl, { headers: header, withCredentials: true })
      .then((res) => {
        // fetch has returned login data
        setIsLoading(false);
        console.log('checkLogin');
        console.log(res)
        if ((res.status === 200)) {
          console.log('checkLogin Sucess: set state as LoggedIn');
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
          console.log('checkLogin Failed')
        }
      })
      .catch((error) => {
        setIsLoggedIn(false);
        if (error.response){
          console.log(error.response.data);
          console.log(error.response.status);
          setIsLoading(false);
        }else if (error.request){
          console.log(error.request);
        }else{
          console.log('Error',error.message);
        }
      });
  };

  useEffect(() => {
    console.log("app loaded")
    handleCheckLogin();
  }, []);
  if (isLoading) {
    return (<Loading />);
  }
  return (
    <Router>
    <Routes>
        <Route path="/login" element={
          <Login
            cookies={csrfAccessToken}
            onCookieChange={handleCookieChange}
            onLogin={handleCheckLogin}
            loginStatus={isLoggedIn}
          />} />
        <Route path="/signup" element ={<Signup />} />
        <Route path="/" element={
          <Loader loading={isLoading}>
            <RequireAuth loginStatus={isLoggedIn} redirectTo="/login">
              <div className="page__wrapper">
                <Nav className="page__wrapper-nav" setLogoutStatus={handleLogout}/>
                  <Home loginStatus={isLoggedIn} className="page__wrapper-component"/>
              </div>
            </RequireAuth>
          </Loader>
        }/>
        {/* <PrivateRoute loading={isLoading} loginStatus={isLoggedIn} onLogin={handleCheckLogin} component={Home} handleLogout={handleLogout}/> */}
        <Route path="/dashboard" element={
            <PrivateRoute loading={isLoading} loginStatus={isLoggedIn} onLogin={handleCheckLogin} component={Dashboard} handleLogout={handleLogout} />
        }/>
        <Route path="/profile" element={
            <PrivateRoute loading={isLoading} loginStatus={isLoggedIn} onLogin={handleCheckLogin} component={Profile} handleLogout={handleLogout} />
        }/>
        {/* <PrivateRoute path="/dashboard" loading={isLoading} loginStatus={isLoggedIn} onLogin={handleCheckLogin} component={Dashboard} handleLogout={handleLogout} /> */}
        {/* <PrivateRoute path="/profile" loading={isLoading} loginStatus={isLoggedIn} onLogin={handleCheckLogin} component={Profile} handleLogout={handleLogout} /> */}
        {/* <PrivateRoute path="/" loading={isLoading} loginStatus={isLoggedIn} onLogin={handleCheckLogin} component={Home} handleLogout={handleLogout}/> */}
      </Routes>
    </Router>
  );
} export default App;
