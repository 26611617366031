import React, { useState, useEffect } from 'react';
import { useNavigate,Navigate } from 'react-router-dom';
import './styles/login.css';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import LoginBtn from '../components/LoginBtn';
// import { Cookies } from 'react-cookie';

const Login = ({
  cookies, onCookieChange, onLogin, loginStatus,
}) => {
  const [inputUsername, setInputUsername] = useState('');
  const [inputPassword, setInputPassword] = useState('');
  const navigate = useNavigate();

  const redirectHome = () => {
    console.log('redirectHome');
    // navigate('/');
    return (<Navigate to="/" />);
  };

  function handleLogin(event,callback) {
    event.preventDefault();
    const loginUrl = `${process.env.REACT_APP_API_URL}/api/auth/login`;
    const payload = { username: inputUsername, password: inputPassword };
    axios.post(loginUrl, payload, { withCredentials: true })
      .then((response) => {
        if (typeof callback === 'function'){
          callback();
        }
        if (response.status === 200) {
          console.log('login success');
          onCookieChange(Cookies.get('csrf_access_token'));
          onLogin();
          redirectHome();
        } else {
          console.log('login failed');
        }
      }, (error) => {
        if (typeof callback === 'function'){
          callback();
        }
        alert(error.response.data.msg)
        console.log(error.response);
      });
  }

  function echo() {
    const echoUrl = `${process.env.REACT_APP_API_URL}/api/auth/echo`;
    const payload = {
      data: 'pepegaclap',
    };
    const header = {
      'X-CSRF-TOKEN': cookies,
    };
    axios.post(echoUrl, payload, { headers: header, withCredentials: true })
      .then((res) => {
        console.log(res);
      }, (error) => {
        console.log(error);
      });
  }

  function handlePushSignup() {
    const signupUrl = '/signup';
    navigate(signupUrl);
  }

  useEffect(() => {
    // onLogin();
    console.log(`Login Status ${loginStatus}`);
    if (loginStatus === true) {
      redirectHome();
    }
  }, [loginStatus]);

  if (loginStatus === true){
    return (<Navigate to="/" />);
  }else{
    return (
      <div className="login-page">
        <div className="login-container">
          <Form>
            <Form.Group className="mb-3" controlId="formLoginUsername">
              <Form.Label>Username</Form.Label>
              <Form.Control type="text" placeholder="Enter your username here" onChange={(e) => setInputUsername(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formLoginPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Enter your password here" onChange={(e) => setInputPassword(e.target.value)} />
            </Form.Group>
            {}
            {/* <Button variant="primary" type="submit" onClick={(e) => handleLogin(e)}>Login</Button> */}
            <LoginBtn onLogin={handleLogin}/>
            <div className="login-container--signup">
              <p>
                Hasnt registered yet?
              </p>
              <Button variant="primary" type="button" onClick={handlePushSignup}>Sign Up</Button>
              {/* <Button variant="primary" type="button" onClick={echo}>Clap</Button> */}
            </div>
          </Form>
        </div>
      </div>
    );
  };
};

Login.propTypes = {
  cookies: PropTypes.string,
  onCookieChange: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  loginStatus: PropTypes.bool.isRequired,
  // onEnterLoginPage: PropTypes.func.isRequired,
};

export default Login;

//   return (
//     <div className="login-page">
//       <div className="login-container">
//         <label htmlFor="login-form--username">
//           Username:
//           <input type="text"
//            id="usernameLoginForm"
// value={inputUsername}
// onChange={(e) => setInputUsername(e.target.value)} />
//         </label>
//         <label htmlFor="login-form--password">
//           Password:
//           <input type="password"
// id="passwordLoginForm"
// value={inputPassword}
// onChange={(e) => setInputPassword(e.target.value)} />
//         </label>
//         <button type="submit" onClick={handleLogin}>Login</button>
//       </div>
//     </div>
//   );
// }; export default Login;
