import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import logout from '../utils/logout';
import FacebookAccount from '../components/FacebookAccount';
import './styles/home.css';

const Home = ({ loginStatus }) => {
  const [facebookAccounts, setFacebookAccounts] = useState([]);
  const navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const handleCheckFacebookBind = async () => {
    const getUrl = `${process.env.REACT_APP_API_URL}/api/facebook/pages`;
    const header = {
      'X-CSRF-TOKEN': Cookies.get('csrf_access_token'),
    };
    await axios.get(getUrl, { headers: header, withCredentials: true })
      .then((res) => {
        console.log(res.data.data);
        if (res.data.data) {
          setFacebookAccounts(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const FacebookAccountList = ({facebookAccounts}) => facebookAccounts.map(
    (facebookAccount) => (
      <FacebookAccount
        key={facebookAccount.facebook_id}
        facebookAccData={facebookAccount}
        checkFacebookBind={handleCheckFacebookBind}
      />
    ),
  )

  const NoFacebookAccount = () => (<FacebookAccount checkFacebookBind={handleCheckFacebookBind} />);

  useEffect(() => {
    console.log('Home page loaded');
    handleCheckFacebookBind();
  }, []);
  return (
    <div className="home-page">
      <div className="home-page__title">
        <h1>Impersonator Checker</h1>
      </div>
      <div className="home-page__body">
        <h2 className="home-page__body-header">
          
        </h2>
        <div className="home-page__body-content">
        {
          (facebookAccounts && facebookAccounts.length>0)
            ? (<div>
                <FacebookAccountList facebookAccounts={facebookAccounts}/>
              </div>)
            : (<NoFacebookAccount />)
        }
        </div>
        
        {/* {facebookBind
          ? (
            <div>
              <h2>Facebook account connected</h2>
              {facebookPages.map((facebookPage) => (<FacebookPages FBPage={facebookPage} />))}
            </div>
          )
          : (<FBLoginBtn handleFBSubscription={() => setFacebookBind(true)} />)} */}
      </div>
    </div>
  );
};
Home.propTypes = {
  loginStatus: PropTypes.bool.isRequired,
};
export default Home;
