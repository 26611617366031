import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/esm/Spinner';
import './styles/loginbtn.css';

const LoginBtn = ({onLogin}) => {
    const [isLoading,setIsLoading] = useState(false);
    const changeLoadState = () => {
        setIsLoading(false);
    }
    const loginClickHandler = (e) => {
        setIsLoading(true);
        onLogin(e,changeLoadState)
    }
    if (isLoading){
        return(
            <Button
            className="login-btn"
            variant="primary" 
            type="submit" 
            >
            <Spinner as="span" role="status" animation="border" size="sm" />
        </Button>
        )
    }
    return(
        <Button
            className="login-btn"
            variant="primary" 
            type="submit" 
            onClick={(e) => loginClickHandler(e)}
            >
            Login
        </Button>
    )
};

LoginBtn.propTypes = {
    onLogin: PropTypes.func.isRequired,
};

export default LoginBtn;