import React, { useState, useEffect } from 'react';
import { useHistory, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import './styles/signup.css';

const Signup = () => {
  const navigate = useNavigate();
  const [registerUsername, setRegisterUsername] = useState('');
  const [registerPassword, setRegisterPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');
  const [usernameValidity, setUsernameValidity] = useState(false);
  const [passwordValidity, setPasswordValidity] = useState(false);
  const [formValidity, setFormValidity] = useState(false);
  const [usernameExists, setUsernameExists] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const validatePassword = () => {
    if ((registerPassword === verifyPassword) && (registerPassword.length > 7)) {
      setPasswordValidity(true);
    } else {
      setPasswordValidity(false);
    }
  };

  const validateUsername = () => {
    console.log('validate username call');
    const regexPattern = '^(?=.{6,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$';
    const usernameRegex = new RegExp(regexPattern);
    const valid = usernameRegex.test(registerUsername);
    if (valid) {
      setUsernameValidity(true);
    } else {
      setUsernameValidity(false);
    }
  };

  const checkFormValidity = () => {
    if (usernameValidity && passwordValidity && !usernameExists) {
      setFormValidity(true);
    } else {
      setFormValidity(false);
    }
  };

  const handleUsernameChange = (event) => {
    setIsLoading(false);
    setUsernameExists(false);
    setRegisterUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setIsLoading(false);
    setRegisterPassword(event.target.value);
  };

  const handleVerifyPasswordChange = (event) => {
    setIsLoading(false);
    setVerifyPassword(event.target.value);
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();
    const signupUrl = `${process.env.REACT_APP_API_URL}/api/auth/register`;
    const payload = { username: registerUsername, password: registerPassword };
    axios.post(signupUrl, payload, { withCredentials: true })
      .then((res) => {
        if ((res.statusText === 'OK') && (res.status === 200)) {
          console.log('checkRegister Ok');
          navigate('/');
        }
      }).catch((err) => {
        if (err.response.status === 409) {
          console.log('checkRegister 409');
          setUsernameExists(true);
          setUsernameValidity(false);
        }
      });
  };

  useEffect(() => {
    checkFormValidity();
  }, [usernameValidity, passwordValidity]);

  useEffect(() => {
    validatePassword();
  }, [registerPassword, verifyPassword]);

  useEffect(() => {
    validateUsername();
  }, [registerUsername]);

  return (
    <div className="signup-page">
      <div className="signup-container">
        <h2>Register</h2>
        <Form validated={formValidity} onSubmit={handleSubmitForm}>
          <Form.Group className="mb-3" controlId="formLoginUsername">
            <Form.Label>Username</Form.Label>
            <InputGroup hasValidation>
              <Form.Control type="text" required isInvalid={!usernameValidity} placeholder="Enter your username here" onChange={(e) => { handleUsernameChange(e); }} />
              {!usernameValidity && !isLoading && !usernameExists
                && (
                  <Form.Control.Feedback type="invalid" tooltip>
                    Please choose a valid username (min length 6)
                  </Form.Control.Feedback>
                )}
              {(usernameExists && !isLoading)
                && (
                  <Form.Control.Feedback type="invalid" tooltip>
                    username already exists
                  </Form.Control.Feedback>
                )}
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formLoginPassword">
            <Form.Label>Password</Form.Label>
            <InputGroup hasValidation={!passwordValidity}>
              <Form.Control type="password" required isInvalid={!passwordValidity} placeholder="Enter your password here" onChange={(e) => handlePasswordChange(e)} />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formLoginPasswordVerification">
            <Form.Label>Verify Password</Form.Label>
            <InputGroup hasValidation={!passwordValidity}>
              <Form.Control type="password" required isInvalid={!passwordValidity} placeholder="Verify your password here" onChange={(e) => handleVerifyPasswordChange(e)} />
              {(!passwordValidity && !isLoading) && (
              <Form.Control.Feedback type="invalid" tooltip>
                Password is not same or too short (minimum length is 8)
              </Form.Control.Feedback>
              )}
            </InputGroup>
          </Form.Group>
          <Button variant="primary" size="lg" type="submit" disabled={!formValidity}>Register</Button>
        </Form>
      </div>
    </div>
  );
}; export default Signup;
