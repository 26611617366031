import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/esm/Spinner';
import './styles/pagebutton.css';
const PageButton = ({
  expired, subscribed, handleRefresh, handleUnsubscribe, handleSubscribe, loading
}) => {
  if (loading) {
    return (<Button variant="primary" className="page-button">
        <Spinner as="span" role="status" animation="border" size="sm" />
        <div>
          Loading...
        </div>
    </Button>);
  }
  if (expired) {
    return (<Button variant="primary" className="page-button" onClick={() => handleRefresh()}>Refresh Facebook Page Data</Button>);
  }
  if (subscribed) {
    return (<Button variant="primary" className="page-button" onClick={() => handleUnsubscribe()}>unsubscribe to Webhook</Button>);
  }
  return (<Button variant="primary" className="page-button" onClick={() => handleSubscribe()}>Subscribe to Webhook</Button>);
};
PageButton.propTypes = {
  expired: PropTypes.bool.isRequired,
  subscribed: PropTypes.bool.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  handleUnsubscribe: PropTypes.func.isRequired,
  handleSubscribe: PropTypes.func.isRequired,
};
export default PageButton;
