import React,{useState} from 'react';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import PropTypes from 'prop-types';
import { FacebookLoginClient } from '@greatsumini/react-facebook-login';
import Cookies from 'js-cookie';

const FBLogoutBtn = ({ FbId, checkFBData }) => {
  const [isLoading,setIsLoading] = useState(false)
  const logoutFB = () => {
    const logoutUrl = `${process.env.REACT_APP_API_URL}/api/auth/facebook/logout`;
    const header = {
      'X-CSRF-TOKEN': Cookies.get('csrf_access_token'),
    };
    const payload = {
      id: FbId,
    };
    setIsLoading(true)
    axios.post(logoutUrl, payload, { headers: header, withCredentials: true })
      .then((res) => {
        setIsLoading(false)
        console.log(res);
        if (res.data.success === true) {
          FacebookLoginClient.logout((resLogout) => {
            alert('succesfully facebook logout');
            console.log(resLogout);
          });
        }
        checkFBData();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (<Button variant="primary" onClick={() => logoutFB()}>Sign Out Facebook Account</Button>);
};
FBLogoutBtn.propTypes = {
  FbId: PropTypes.number.isRequired,
  checkFBData: PropTypes.func.isRequired,
}; export default FBLogoutBtn;
