import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
// import Button from 'react-bootstrap/Button';
import Cookies from 'js-cookie';
import PageButton from './PageButton';

const FacebookPages = ({ FBPage }) => {
  const [isSubscribed, setIsSubscribed] = useState(FBPage.subscription);
  const [isExpired, setIsExpired] = useState(FBPage.expired);
  const [isLoading, setIsLoading] = useState(false)
  const handleSubscribe = async () => {
    const postUrl = `${process.env.REACT_APP_API_URL}/api/facebook/page/${FBPage.page_id}/subscription`;
    const header = {
      'X-CSRF-TOKEN': Cookies.get('csrf_access_token'),
    };
    const payload = {
      subscribed_field: 'general_info',
    };
    setIsLoading(true)
    await axios.post(postUrl, payload, { headers: header, withCredentials: true })
      .then((res) => {
        setIsLoading(false)
        console.log(res);
        if (res.data.success) {
          setIsSubscribed(true);
          alert('successfully subscribed to webhook');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUnsubscribe = async () => {
    const delUrl = `${process.env.REACT_APP_API_URL}/api/facebook/page/${FBPage.page_id}/subscription`;
    const header = {
      'X-CSRF-TOKEN': Cookies.get('csrf_access_token'),
    };
    // const payload = {
    //   page_id: FBPage.page_id,
    // };
    setIsLoading(true)
    await axios.delete(delUrl, { headers: header, withCredentials: true })
      .then((res) => {
        setIsLoading(false)
        console.log(res);
        if (res.data.success) {
          setIsSubscribed(false);
          alert('successfully unsubscribed to webhook');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRefresh = async () => {
    const patchUrl = `${process.env.REACT_APP_API_URL}/api/facebook/page/${FBPage.page_id}/subscription`;
    const header = {
      'X-CSRF-TOKEN': Cookies.get('csrf_access_token'),
    };
    const payload = {
      page_id: FBPage.page_id,
    };
    setIsLoading(true);
    await axios.patch(patchUrl, payload, { headers: header, withCredentials: true })
      .then((res) => {
        setIsLoading(false)
        console.log(res);
        if (res.data.success) {
          setIsExpired(false);
        }
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err);
      });
  };

  return (
    <div className="facebook-page">
      <h3>{FBPage.page_name}</h3>
      <PageButton
        handleRefresh={handleRefresh}
        handleSubscribe={handleSubscribe}
        handleUnsubscribe={handleUnsubscribe}
        expired={isExpired}
        subscribed={isSubscribed}
        loading={isLoading}
      />
    </div>
  );
};
FacebookPages.propTypes = {
  FBPage: PropTypes.shape({
    page_id: PropTypes.number,
    page_name: PropTypes.string,
    instagram_id: PropTypes.number,
    subscription: PropTypes.bool,
    expired: PropTypes.bool,
  }).isRequired,
};
export default FacebookPages;
