import React from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Link, useNavigate } from 'react-router-dom';
import Logout from '../pages/Logout';
import './styles/nav.css';

const Nav = ({setLogoutStatus}) => {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const handleLogout = () => {
    const postUrl = `${process.env.REACT_APP_API_URL}/api/auth/logout`;
    const header = {
      'X-CSRF-TOKEN': Cookies.get('csrf_access_token'),
    };
    axios.post(postUrl, {}, { headers: header, withCredentials: true })
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          console.log(`logout res data: ${res.data.msg}`);
          setLogoutStatus();
          navigate('/login');
        } else {
          navigate('/login');
        }
      });
  };
  return (
    <div className="nav">
      <div className="nav__wrapper-head">
        <Link to="/" className="nav__link-home">
          <h2>Impersonator Checker</h2>
        </Link>
      </div>
      <div className="nav__wrapper-body">
        <Link to="/dashboard" className="nav__link">
          <p>Dashboard</p>
        </Link>
        <Link to="/profile" className="nav__link">
          <p>Profile</p>
        </Link>
        <div className="nav__link">
          {/* <button onClick={handleLogout} type="button">Logout</button> */}
          {/* <p>Logout</p> */}
          <Logout handleLogout={handleLogout}/>
        </div>
      </div>
    </div>
  );
}; export default Nav;
