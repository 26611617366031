/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import NavRoute from './NavRoute';
import Loading from '../pages/Loading';

const PrivateRoute = ({
  component, loading, loginStatus, ...rest
}) => {
  if (loading) {
    return (<Loading />);
  }
  return (loginStatus
    ? (<NavRoute {...rest} loginStatus={loginStatus} component={component} />)
    : (<Navigate to="/login" />)
  );
};
PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  loginStatus: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};
export default PrivateRoute;
